import React, { useState } from 'react';
import { Form, Spin, message } from 'antd';
import { useIntl } from 'react-intl';
import { Modal } from 'src/components/ChowBusComponents';
import { resetPasswordByEmail, verifyEmailCode } from 'src/services/passwords';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordModal = ({ locale, onCancel, verifyMeans, open, email, isChecking }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleOk = async () => {
    let hasErrors = false;
    let values;

    try {
      values = await form.validateFields();
    } catch (e) {
      hasErrors = true;
    }

    if (hasErrors) return;

    setIsUpdating(true);
    let response;

    try {
      response = await verifyEmailCode({ email, code: values.code });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      setIsUpdating(false);
      return;
    }

    try {
      response = await resetPasswordByEmail({ email, ...values });
    } catch (e) {
      response = { success: false };
    }

    setIsUpdating(false);
    if (!response.success) return;
    message.success(formatMessage({ id: 'resetPassword.success' }));
    onCancel();
  };

  const modalProps = {
    title: formatMessage({ id: 'resetPassword.title' }),
    open,
    width: 560,
    onCancel,
    onOk: handleOk,
    centered: true,
    confirmLoading: isUpdating,
  };

  return (
    <Modal {...modalProps}>
      <Spin spinning={isChecking}>
        <ResetPasswordForm form={form} locale={locale} verifyMeans={verifyMeans} email={email} />
      </Spin>
    </Modal>
  );
};

export default ResetPasswordModal;
