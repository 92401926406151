import React, { useState, useEffect, useMemo } from 'react';
import { Input, Progress } from 'antd';
import { CloseOutlined, CheckOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import zxcvbn from 'zxcvbn';
import { getItemName } from '../../utils/utils';
import styles from './index.less';

export const fullMatchRule = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@$#%\^*?&_\-\+=\.]{8,}/;

const rules = [
  {
    rule: /[A-Za-z\d!@$#%\^*?&_\-\+=\.]{8,}/,
    name: 'Have at least 8 Characters',
    foreign_name: '至少8个字符',
    fullMatch: false,
  },
  {
    rule: /[a-z]/,
    name: 'Include at least 1 lowercase letter',
    foreign_name: '包括至少1个小写字母',
    fullMatch: false,
  },
  {
    rule: /[A-Z]/,
    name: 'Include at least 1 uppercase letter',
    foreign_name: '包括至少1个大写字母',
    fullMatch: false,
  },
  {
    rule: /\d+/,
    name: 'Include at least 1 digital',
    foreign_name: '包括至少1个数字',
    fullMatch: false,
  },
  {
    rule: fullMatchRule,
    name: 'Match',
    foreign_name: '匹配',
    fullMatch: true,
  },
];

const passwordStrengthDegrees = [
  {
    degree: 'week',
    name: 'Weak',
    foreign_name: '弱',
    scores: [0, 1],
    backgroundColor: '#E00051',
    percent: 33,
  },
  {
    degree: 'medium',
    name: 'Medium',
    foreign_name: '中等',
    scores: [2],
    backgroundColor: '#FDC229',
    percent: 66,
  },
  {
    degree: 'strong',
    name: 'Strong',
    foreign_name: '强',
    scores: [3, 4],
    backgroundColor: '#A3C95E',
    percent: 100,
  },
];

const MatchItemRuleIcon = ({ value, matched }) => {
  if (!value) return <span className="dot" />;
  if (matched) return <CheckOutlined style={{ color: '#15D0C5' }} />;
  return <CloseOutlined style={{ color: '#E00051' }} />;
};

const MatchItemRule = (props) => {
  const { ruleItem, value, locale, onMatchChanged } = props;
  const { rule, fullMatch } = ruleItem;
  let regExp = rule;
  if (!(regExp instanceof RegExp)) regExp = new RegExp(rule);
  const matched = regExp.test(value);
  if (fullMatch && typeof onMatchChanged === 'function') onMatchChanged(matched);

  if (fullMatch && !matched) return null;

  return (
    <div className="rule-item">
      <span className="icon-wrapper">
        <MatchItemRuleIcon value={value} matched={matched} />
      </span>
      {getItemName(ruleItem, locale)}
    </div>
  );
};

const PasswordStrengthDegree = ({ value, locale }) => {
  const [score, setScore] = useState(0);

  useEffect(() => {
    let score = 0;
    if (value && fullMatchRule.test(value)) {
      try {
        score = zxcvbn(value).score;
      } catch (e) {
        score = 0;
      }
    }
    setScore(score);
  }, [value]);

  const strengthDegree = useMemo(() => {
    return passwordStrengthDegrees.find((_) => _.scores.includes(score)) || {};
  }, [score]);

  return (
    <div className="strength-degree">
      <div className="degree-desc">{getItemName(strengthDegree, locale)}</div>
      <Progress
        style={{ width: 80, height: 4 }}
        percent={strengthDegree.percent}
        strokeColor={strengthDegree.backgroundColor}
        showInfo={false}
      />
    </div>
  );
};

const PasswordStrengthChecker = ({ value, locale, onMatchChanged }) => {
  return (
    <div className="checker">
      <div className="checker-rules">
        <div className="rules-title">
          <FormattedMessage id="password.requirement.title" />
        </div>
        {rules.map((rule, index) => (
          <MatchItemRule key={index} ruleItem={rule} value={value} locale={locale} onMatchChanged={onMatchChanged} />
        ))}
      </div>
      <div className="password-strength">
        <div className="strength-title">
          <FormattedMessage id="password.strength" />
        </div>
        <PasswordStrengthDegree value={value} locale={locale} />
      </div>
    </div>
  );
};

const PasswordInput = ({
  value,
  onChange,
  placeholder,
  locale,
  onMatchChanged,
  size = 'large',
  style = { width: '100%' },
}) => {
  const [showChecker, setShowChecker] = useState(false);
  const { formatMessage } = useIntl();

  const handlePasswordChange = (event) => {
    onChange(event.target.value);
  };

  const handleFocus = () => {
    setShowChecker(true);
  };

  const handleBlur = () => {
    setShowChecker(false);
  };

  return (
    <div className={styles.passwordStrengthChecker} style={style}>
      <Input.Password
        value={value}
        onChange={handlePasswordChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        size={size}
        autoComplete="current-password"
        placeholder={placeholder || formatMessage({ id: 'component.passwordInput.placeholder' })}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
      {showChecker && <PasswordStrengthChecker value={value} locale={locale} onMatchChanged={onMatchChanged} />}
    </div>
  );
};

export default PasswordInput;
