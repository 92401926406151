import React from 'react';
import { FONT_FACES } from 'src/consts/fonts';

const Fonts = () => {
  const fontFaceContent = Object.values(FONT_FACES).join(' ');

  return (
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: fontFaceContent,
      }}
    ></style>
  );
};

export default Fonts;
