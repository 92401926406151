import React, { useState, useEffect, useRef, createRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './index.less';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

const ReactCodeInputGrid = forwardRef((props, ref) => {
  const inputRef = useRef();
  const { disabled, isValid, value, onChange, index, size } = props;

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      inputRef.current.value = val;
    },
    focusAndSelect: () => {
      inputRef.current.focus();
      inputRef.current.select();
    },
  }));

  const handleFocus = (e) => {
    e.target.select(e);
  };

  const handleChange = (e) => {
    const { type, forceUppercase } = props;

    let newValue = String(e.target.value);
    if (forceUppercase) newValue = value.toUpperCase();
    if (type === ReactCodeInput.NUMBER) newValue = newValue.replace(/\D+/g, '');
    inputRef.current.value = newValue;
    handleInputChange(newValue);
    if (newValue.length) props.focusNext(props.index);
  };

  const handleInputChange = useCallback(
    (newValue) => {
      typeof onChange === 'function' && onChange(props.index, newValue);
    },
    [props.index]
  );

  const handleKeyDown = (e) => {
    const { filterKeyCodes } = props;

    if (filterKeyCodes && filterKeyCodes.length > 0) {
      filterKeyCodes.forEach((item) => {
        if (item === e.keyCode) {
          e.preventDefault();
          return true;
        }

        return false;
      });
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        inputRef.current.value = '';
        handleInputChange('');
        props.focusPrevious(index);
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        props.focusPrevious(index);
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        props.focusNext(index);
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.target.type === 'number') {
          e.preventDefault();
        }
        break;

      default:
        break;
    }
  };

  return (
    <input
      id={`code-input-${index}`}
      ref={inputRef}
      autoFocus={false}
      min={0}
      max={9}
      maxLength={1}
      autoComplete="off"
      onFocus={handleFocus}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      data-valid={isValid}
      className={classNames(size, { disabled: disabled })}
    />
  );
});

const ReactCodeInput = (props) => {
  const [inputs, setInputs] = useState('');
  const { fields, onChange } = props;
  const ref = useRef(
    Array(fields)
      .fill()
      .map((_) => createRef())
  );

  useEffect(() => {
    if (props.autoFocus) {
      const firstInput = ref.current[0];
      firstInput.current.focusAndSelect();
    }
  }, []);

  useEffect(() => {
    if (!props.value || props.value === inputs) return;
    let newInputs = props.value;
    if (props.forceUppercase) newInputs = newInputs.toUpperCase();
    setInputs(newInputs);
    ref.current.forEach((inputRef, index) => {
      inputRef.current.setValue(newInputs[index] || '');
    });
  }, [props.value]);

  const handleChange = (index, val) => {
    setInputs((oldValue) => {
      const newValue = (oldValue || '').split('');
      newValue[index] = val;
      const value = newValue.join('');
      typeof onChange === 'function' && onChange(value);
      return value;
    });
  };

  const focusPrevious = (index) => {
    if (index === 0) return;
    const inputRefs = ref.current;
    const inputRef = inputRefs[index - 1];
    inputRef.current.focusAndSelect();
  };

  const focusNext = (index) => {
    if (index === fields - 1) return;
    const inputRefs = ref.current;
    const inputRef = inputRefs[index + 1];
    inputRef.current.focusAndSelect();
  };

  return (
    <div className={classes.ReactCodeInput}>
      {ref.current.map((inputRef, index) => (
        <ReactCodeInputGrid
          {...props}
          key={index}
          ref={inputRef}
          index={index}
          onChange={handleChange}
          focusPrevious={focusPrevious}
          focusNext={focusNext}
        />
      ))}
    </div>
  );
};

ReactCodeInput.defaultProps = {
  autoFocus: false,
  isValid: true,
  disabled: false,
  forceUppercase: false,
  fields: 4,
  value: '',
  type: ReactCodeInput.NUMBER,
  size: 'large',
  filterKeyCodes: [189, 190],
};

ReactCodeInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number']),
  size: PropTypes.oneOf(['large', 'middle']),
  fields: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  forceUppercase: PropTypes.bool,
  filterKeyCodes: PropTypes.array,
};

ReactCodeInput.NUMBER = 'number';
ReactCodeInput.TEXT = 'text';

export default ReactCodeInput;
