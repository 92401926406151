import React, { useCallback, useState } from 'react';
import { Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import ResetPasswordForm from './ResetPasswordForm';
import { resetPasswordByEmail, verifyEmailCode } from 'src/services/passwords';
import styles from './style.less';

const ResetPasswordByEmail = ({ email, verifyMeans, locale, onResetSuccess }) => {
  const [form] = Form.useForm();
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const handleResetPassword = useCallback(async () => {
    let hasErrors = false;
    let values;

    try {
      values = await form.validateFields();
    } catch (e) {
      hasErrors = true;
    }

    if (hasErrors) return;

    setIsResettingPassword(true);
    let response;

    try {
      response = await verifyEmailCode({ email, code: values.code });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      setIsResettingPassword(false);
      return;
    }

    try {
      response = await resetPasswordByEmail({ email, ...values });
    } catch (e) {
      response = { success: false };
    }

    setIsResettingPassword(false);
    if (!response.success || typeof onResetSuccess !== 'function') return;
    onResetSuccess();
  }, [email, onResetSuccess]);

  return (
    <div className={styles.ResetPasswordByEmail}>
      <ResetPasswordForm form={form} email={email} verifyMeans={verifyMeans} locale={locale} />
      <Button block type="primary" size="large" onClick={handleResetPassword} loading={isResettingPassword}>
        <FormattedMessage id="resetPassword.reset" />
      </Button>
    </div>
  );
};

export default ResetPasswordByEmail;
