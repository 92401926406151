import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, Radio, Row, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonWithCountdown from '../ButtonWithCountdown';
import ReactCodeInput from '../CodeInput';
import PasswordInput from '../PasswordInput';
import { sendVerifyCode } from 'src/services/passwords';
import { VERIFY_BY_EMAIL } from 'src/consts';

const PIN_LEN = 4;

const ReactPasswordForm = ({ form, email, verifyMeans, locale }) => {
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const [isSendingVerifyCode, setIsSendingVerifyCode] = useState(false);
  const [isSendVerifyCodeFailed, setIsSendVerifyCodeFailed] = useState(false);

  useEffect(() => {
    form.setFields([{ name: 'means', value: verifyMeans[0] }]);
  }, [verifyMeans]);

  const handleSendVerifyCode = useCallback(async () => {
    let response;
    setIsSendingVerifyCode(true);
    const currentMeans = form.getFieldValue('means');

    try {
      response = await sendVerifyCode({ email, means: currentMeans });
    } catch (e) {
      response = { success: false };
    }

    setIsSendingVerifyCode(false);
    setIsSendVerifyCodeFailed(!response.success);
    if (!response.success) return;

    const text =
      currentMeans === VERIFY_BY_EMAIL
        ? formatMessage({ id: 'resetPassword.sentVerifyCodeToEmail' }, { email })
        : formatMessage({ id: 'resetPassword.sentVerifyCodeToPhone' });
    message.success(text);
    ref.current && ref.current.startCountdown();
  }, [email, locale]);

  const validatePasswordConfirmation = async (_, value) => {
    const password = form.getFieldValue('password');
    if (!password) return Promise.resolve();

    if (password !== value) {
      return Promise.reject(formatMessage({ id: 'resetPassword.field.password.not-match' }));
    }

    return Promise.resolve();
  };

  return (
    <Form form={form}>
      <Form.Item name="means">
        <Radio.Group size="large">
          {verifyMeans.map((mean) => (
            <Radio value={mean} key={mean}>
              <FormattedMessage id={`resetPassword.means.${mean}`} />
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Form.Item
            name="code"
            label={<FormattedMessage id="resetPassword.field.code" />}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'resetPassword.field.pin.empty' }),
              },
              {
                len: PIN_LEN,
                message: formatMessage({ id: 'resetPassword.field.pin.lengthNotMatch' }, { len: PIN_LEN }),
              },
            ]}
          >
            <ReactCodeInput type="number" fields={PIN_LEN} autoFocus={false} />
          </Form.Item>
        </Col>
        <Col>
          <ButtonWithCountdown
            ref={ref}
            onClick={handleSendVerifyCode}
            loading={isSendingVerifyCode}
            size="large"
            style={{ marginBottom: 24 }}
          >
            <FormattedMessage
              id={isSendVerifyCodeFailed ? 'resetPassword.resendVerifyCode' : 'resetPassword.sendVerifyCode'}
            />
          </ButtonWithCountdown>
        </Col>
      </Row>
      <Form.Item
        name="password"
        label={<FormattedMessage id="resetPassword.field.password" />}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'resetPassword.field.password.required' }),
          },
        ]}
      >
        <PasswordInput size="large" autoComplete="new-password" locale={locale} />
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        dependencies={['password']}
        label={<FormattedMessage id="resetPassword.field.confirmationPassword" />}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'resetPassword.field.confirmationPassword.required' }),
          },
          {
            validator: validatePasswordConfirmation,
          },
        ]}
      >
        <PasswordInput size="large" autoComplete="new-password" locale={locale} />
      </Form.Item>
    </Form>
  );
};

export default ReactPasswordForm;
