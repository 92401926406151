import { AUTH_API_PREFIX } from '../consts';
import { GET, POST, PUT } from '../utils/request';
import { POS_AUTH_CLIENT_ID } from 'src/consts/third_party';
import { encrypt } from 'src/utils/encrypt';

export function getResetOptions(email) {
  return GET(
    `${AUTH_API_PREFIX}/api/v1/passwords/restaurateurs/reset_options`,
    { email },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID }, noNeedLogin: true, parseAsJSONApi: true }
  );
}

export function sendVerifyCode({ email, means }) {
  return POST(
    `${AUTH_API_PREFIX}/api/v1/passwords/restaurateurs/reset`,
    { email, means },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID }, noNeedLogin: true }
  );
}

export function verifyEmailCode({ email, code }) {
  return POST(
    `${AUTH_API_PREFIX}/api/v1/passwords/restaurateurs/verify`,
    { email, code },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID }, silence: true, noNeedLogin: true }
  );
}

export async function resetPasswordByEmail({ email, password, password_confirmation, code }) {
  const encryptedData = await encrypt({ password, password_confirmation });
  return PUT(
    `${AUTH_API_PREFIX}/api/v1/passwords/restaurateurs`,
    { email, code, ...encryptedData },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID }, noNeedLogin: true }
  );
}
