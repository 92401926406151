import React, { useEffect } from 'react';
import { Form, Button, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { getSessionItem, KEYS } from 'src/store/storage';

const ValidateEmail = (props) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { onValidateEmail, isLoadingResetOptions } = props;

  useEffect(() => {
    const email = getSessionItem(KEYS.email, '');
    form.setFieldsValue({ email });
  }, []);

  return (
    <Form form={form} onFinish={onValidateEmail}>
      <Form.Item
        name="email"
        placeholder={formatMessage({ id: 'resetPassword.field.email' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'resetPassword.field.email.required' }),
          },
          {
            type: 'email',
            message: formatMessage({ id: 'resetPassword.field.email.wrong-format' }),
          },
        ]}
      >
        <Input autoComplete="off" type="email" size="large" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit" loading={isLoadingResetOptions}>
          <FormattedMessage id="resetPassword.field.validateEmail" />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ValidateEmail;
