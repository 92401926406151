import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'antd';

const ButtonWithCountdown = forwardRef((props, ref) => {
  const { countdownSeconds = 60, countdownOnInit = false, children, onCountdownEnd, ...extra } = props;
  const [countdownRemainSeconds, setCountdownRemainSeconds] = useState(0);
  const intervalRef = useRef(null);

  const startCountdown = useCallback(() => {
    setCountdownRemainSeconds(countdownSeconds);
    intervalRef.current = setInterval(() => {
      setCountdownRemainSeconds((prev) => {
        const seconds = prev - 1;
        if (seconds <= 0) {
          clearInterval(intervalRef.current);
          if (typeof onCountdownEnd === 'function') onCountdownEnd();
        }
        return seconds;
      });
    }, 1000);
  }, [countdownSeconds]);

  useImperativeHandle(ref, () => ({
    startCountdown,
  }));

  useEffect(() => {
    if (countdownOnInit) startCountdown();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Button {...extra} disabled={countdownRemainSeconds > 0}>
      {children}
      {countdownRemainSeconds > 0 && ` (${countdownRemainSeconds}s)`}
    </Button>
  );
});

export default ButtonWithCountdown;
